import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const ResourcesPage = () => (
  <Layout>
    <SEO title="Patient Resources" description="View tips for using crutches, pre- and post-operation instructions and other patient resources from Memphis podiatrist Dr. Butler."/>
    <section className="black">
      <div className="services">
        <h1>Patient Resources</h1>
        <h2>Pre- and Post-operation Info</h2>
      </div>
    </section>
    <svg xmlns="http://www.w3.org/2000/svg" className="border" viewBox="0 0 1440 320" preserveAspectRatio="none">
      <path className="black" d="M0,96L80,101.3C160,107,320,117,480,133.3C640,149,800,171,960,160C1120,149,1280,107,1360,85.3L1440,64L1440,0L1360,0C1280,0,1120,0,960,0C800,0,640,0,480,0C320,0,160,0,80,0L0,0Z"></path>
    </svg>
    <section className="medium">
      <h3>Before Surgery</h3>
        <p>Here are a few things to keep in mind before your operation:</p>
        <ul>
          <li>Please make arrangements to have a responsible adult with you for 24 hours following
            your surgery. You will need to arrange for someone to drive you home from the surgery
            center/hospital, you are not allowed to take a taxi.</li>
          <li>Plan to take adequate time off from work following your surgery. Rest and elevation are
            essential for good healing. If you have young children at home, arrange for additional
            help to care for them.</li>
          <li>Stop over-the-counter anti-inflammatory medicines 7 days prior to surgery. This includes
            Motrin, Advil, Ibuprofen, Aspirin, Aleve and Naproxen. Most prescription arthritis
            medicine also needs to be stopped, please check with the doctor. Do not stop your
            regular prescription medications unless instructed to do so by the doctor or
            anesthesiologist. Blood thinners (Coumadin, Warfarin, Plavix, Aspirin) need to be
            stopped 4 days prior to surgery, please check with the ordering doctor.</li>
          <li>Several vitamin and herbal supplements can affect the outcome of your surgery. We
            recommend that you stop these at least two weeks prior to surgery. Bilberry, Cayenne,
            Dong Quai, Echinacea, Feverfew, Fish Oil, Garlic, Ginger, Ginkgo Biloba, Ginseng,
            Hawthorne, Kava Kava, Licorice Root, Ma Haung, Red Clover, St. John's Wort,
            Valerian, Vitamin E, Yohimbe.</li>
          <li>Diet drugs such as Meridia, Phentermine, Tenuate, and any weight control supplements
            containing Ephedra must be stopped at least two weeks prior to surgery. These can cause
            dangerous elevation of blood pressure and heart rate.</li>
          <li>You will need crutches or a walker for ambulation after your surgery. These are
            available for purchase through the office, or may be rented from a medical supply
            company.</li>
          <li>Do not eat or drink after midnight the night before your surgery unless otherwise
            instructed by the physician.</li>
        </ul>

        <h3>After Surgery</h3>
        <p>Here's what you should expect after your operation. Keep in mind, if sedation was given, you should not
          drive, operate heavy or complicated equipment, or make any legal decisions for at least 24 hours.</p>
        <h4>Pain</h4>
        <p>Following surgery every patient experiences pain. Our goal is to make sure your pain is well controlled. You
          will be given prescriptions for one or more medications. Please refer to the medication information sheet that
          was included in your packet. Common side effects include dizziness, sleepiness, night sweats, constipation,
          and nausea.Often eating prior to taking pain medicine will prevent nausea. </p>
        <p>If a reaction occurs, stop taking the medication and call the office. If it is a serious reaction, report to
          your local emergency room. If you have a nerve block your foot will be numb for several hours. Sensation will
          return (and pain with it) in a few hours. Begin your pain medication BEFORE your block wears off and take it
          routinely for the first two to three days. DO NOT wait until you have pain to start your pain medicine.</p>
        <p>Post-operation Medication Instructions:</p>
        <ul>
          <li><strong>Oxycodone/Acetaminophen (Percocet), Demerol, or Dilaudid: </strong>This medication is given for
            the first 5 to 7 days after surgery for severe pain. It is very strong and potentially addictive. It is very
            effective for pain without a significant risk of addiction when used for a short time. This medication is
            NOT usually refilled because of the addictive potential. Take as directed on the label.</li>
          <li><strong>Hydrocodone/Acetaminophen (Norco): </strong>This medication is taken after the course of the
            stronger medications listed above and is used for moderate pain. You will have a 1 to 2 week supply of these
            medications. This will generally be enough to get you through the most painful part of the postoperative
            period. Very occasionally these will be refilled but once again they have the potential to be addictive and
            refills are monitored by the pharmacy. Take as directed. Do NOT take Tylenol (Acetaminophen) while taking
            these medications.</li>
          <li><strong>Promethazine (Phenergan): </strong>This medication prevents vomiting and nausea. It is taken only
            as needed for nausea or vomiting. Some patients will not require this medication and some will be given a
            prescription prior to discharge from the hospital. These types of medications can be given in pill form
            (ORAL) or suppository (RECTAL). Take as directed on the label.</li>
        </ul>
        <p>All of these medications can cause dizziness, drowsiness, sedation, itching and nausea. These side effects
          can be very serious and you should have someone with you when taking these medications. Stop the medication if
          you have more than mild symptoms. You can NOT drive or operate heavy machinery when taking pain medications.
        </p>
        <p>All of these medications can be constipating. Stool softeners and laxatives are helpful. You can buy these
          without a prescription. Colace or Senokot-S are gentle laxatives, Dulcolax tablets or suppository form is
          stronger.</p>
        <p>Regular home medications should be resumed and/or prescribed by your regular doctor.</p>
        <p>Call the office to report serious side effects. Please contact your pharmacy if you need a refill.</p>

        <h4>Swelling</h4>
        <p>Swelling occurs after any surgery and can be treated and prevented with ice and elevation. Your extremity
          should be elevated well above the level or your heart (unless instructed otherwise by the doctor or nurse).
          Elevate as much as possible for the first 7 days after surgery. Use ice packs intermittently to reduce pain
          and swelling. Be careful to avoid putting ice on unprotected skin, this could cause frostbite. If you are in a
          cast, it is important to wiggle your toes and bend your knee on the operative side to promote good
          circulation.</p>
        <h4>Dressings/Casts</h4>
        <p>Whether you are in a cast, splint, walking boot or post-op shoe it is important to keep the dressing or cast
          clean and dry. Do not remove your dressing unless instructed to do so by the doctor. Dressing and taping is
          used to maintain the surgical correction. If the gauze and tape dressing becomes too tight, you can split it
          with scissors, loosen it and then apply additional tape to secure it.</p>
        <p>If your cast or splint is too tight please call the office or page the doctor. Dressings and cast must be
          securely covered for showers. Commercial cast covers are available at most pharmacies, or use double plastic
          bags and secure with a heavy tape. If your cast gets wet, use a hair dryer to blow it dry. Moisture under a
          cast or dressing can cause problems and should be attended to ASAP. Call the office to report the problem.</p>
        <h4>Bleeding</h4>
        <p>It is very common for bleeding to occur after surgery. If your dressings become soaked with blood, DO NOT
          remove the dressing, but rather re-enforce or cover the dressing. If it continues to soak through please call
          the office or page the doctor if the office is closed.</p>
        <h4>Weight-bearing and Using Crutches</h4>
        <p>It is important to refrain from unnecessary walking during the first few days after surgery. Try to be up for
          bathroom use and meals only. You will be given weight-bearing instructions before you leave the hospital or
          surgery center. For safety use your walker or crutches and do not bear any weight until your anesthesia block
          has worn off completely. If you are given a post-op shoe to wear over your dressing you can bear weight on the
          heel and outside of the foot. DO NOT put pressure on the toes. Patients in casts, splints and walking boots
          should not bear any weight until instructed to by the doctor, use your crutches or walker for ambulation.</p>
        <p><strong>Sizing Crutches</strong></p>
        <p>Even if you’ve already been fitted for crutches, make sure your crutch pads and handgrips are set at the
          proper distance, as follows:</p>
        <ul>
          <li><strong>Crutch pad distance from armpits: </strong>The crutch pads (tops of crutches) should be 1½" to 2"
            (about two finger widths) below the armpits, with the shoulders relaxed.</li>
          <li><strong>Handgrip: </strong>Place it so your elbow is slightly bent – enough so you can fully extend your
            elbow when you take a step.</li>
          <li><strong>Crutch length (top to bottom): </strong>The total crutch length should equal the distance from
            your armpit to about 6" in front of a shoe.
          </li>
        </ul>
        <p><strong>Begin in the “Tripod Position”</strong></p>
        <p>The tripod position is the position in which you stand when using crutches. It is also the position in which
          you begin walking. To get into the tripod position, place the crutch tips about 4" to 6" to the side and in
          front of each foot. Stand on your “good” foot (the one that is weight-bearing).</p>
        <p><strong>Walking with Crutches (Non-weight-bearing)</strong></p>
        <p>If your foot and ankle surgeon has told you to avoid ALL weight-bearing, you will need sufficient upper body
          strength to support all your weight with just your arms and shoulders.</p>
        <ul>
          <li>Begin in the tripod position, remembering to keep all your weight on your “good” (weight-bearing) foot.
          </li>
          <li>Advance both crutches and the affected foot/leg.</li>
          <li>Move the “good” weight-bearing foot/leg forward (beyond the crutches).</li>
          <li>Advance both crutches, and then the affected foot/leg.</li>
          <li>Repeat steps #3 and #4.
          </li>
        </ul>
        <p><strong>Managing Chairs with Crutches</strong></p>
        <p>To get into and out of a chair safely:</p>
        <ul>
          <li>Make sure the chair is stable and will not roll or slide. It must have arms and back support.</li>
          <li>Stand with the backs of your legs touching the front of the seat.</li>
          <li>Place both crutches in one hand, grasping them by the handgrips.</li>
          <li>Hold on to the crutches (on one side) and the chair arm (on the other side) for balance and stability
            while lowering yourself to a seated position, or raising yourself from the chair to stand up.
          </li>
        </ul>
        <p><strong>Managing Chairs without Crutches</strong></p>
        <p>The safest way to go up and down stairs is to use your seat, not your crutches.</p>
        <p><strong>To go up stairs:</strong></p>
        <ul>
          <li>Seat yourself on a low step.</li>
          <li>Move your crutches upstairs by one of these methods: </li>
          <ul>
            <li>If distance and reach allow, place the crutches at the top of the staircase.</li>
            <li>If this isn’t possible, place crutches as far up the stairs as you can, and then move them to the top as
              you progress up the stairs.</li>
          </ul>
          <li>In the seated position, reach behind you with both arms.</li>
          <li>Use your arms and weight-bearing foot/leg to lift yourself up one step.</li>
          <li>Repeat this process one step at a time. (Remember to move the crutches to the top of the staircase if you
            haven’t already done so.)</li>
        </ul>
        <p><strong>To go down stairs:</strong></p>
        <ul>
          <li>Seat yourself on the top step.</li>
          <li>Move your crutches downstairs by sliding them to the lowest possible point on the stairway. Then continue
            to move them down as you progress down the stairs.</li>
          <li>In the seated position, reach behind you with both arms.</li>
          <li>Use your arms and weight-bearing foot/leg to lift yourself down one step.</li>
          <li>Repeat this process one step at a time. (Remember to move the crutches to the bottom of the staircase if
            you haven’t already done so.)
          </li>
        </ul>
        <p><strong>IMPORTANT: Follow These Rules for Safety and Comfort</strong></p>
        <ul>
          <li><strong>Don’t </strong>look down. Look straight ahead as you normally do when you walk.</li>
          <li><strong>Don’t </strong>use crutches if you feel dizzy or drowsy.</li>
          <li><strong>Don’t </strong>walk on slippery surfaces. Avoid snowy, icy, or rainy conditions.</li>
          <li><strong>Don’t </strong>put any weight on the affected foot if your doctor has so advised.</li>
          <li><strong>Do </strong>make sure your crutches have rubber tips.</li>
          <li><strong>Do </strong>wear well-fitting, low-heel shoes (or shoe).</li>
          <li><strong>Do </strong>position the crutch hand grips correctly (see “Sizing Your Crutches”)</li>
          <li><strong>Do </strong>keep the crutch pads 11/2" to 2" below your armpits.</li>
          <li><strong>Do </strong>call your foot and ankle surgeon if you have any questions or difficulties.</li>
        </ul>

        <h4>Observation</h4>
        <p>Watch for change of color in the nail beds of your toes. To check the blanching response:</p>
        <ul>
          <li>Apply pressure to the nail bed, it should become pale.</li>
          <li>Release the pressure and normal color should return quickly. </li>
          <li>If the toe turns dark or very white it is important to call the office or page the doctor.</li>
        </ul>
        <h4>When to Call</h4>
        <p>Please call the office for the following concerns:</p>
        <ul>
          <li>If you have any redness or drainage coming from the dressing.</li>
          <li>Temperature (fever) of 101 degrees or more.</li>
        </ul>
        <p>If you are experiencing a problem or have a concern, please call the office. If the office is closed, remain
          on the line and you will be connected to the answering service. If it is a medical emergency, please go to
          your local emergency room for immediate attention.</p>
        <p>Please call the office to set up your follow-up appointment 7-10 days after your
          surgery.</p>
    </section>
  </Layout>
)

export default ResourcesPage
